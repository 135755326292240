body {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-size: 14px;

  font-family: 'ApexNew, Roboto, sans-serif';
  font-weight: 400;
}

@font-face {
  font-family: 'ApexNew';
  src: url('@/assets/fonts/ApexNew-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Friz Quadrata';
  src: url('@/assets/fonts/friz-quadrata-std-medium-5870338ec7ef8.woff')
    format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
